html,
body {
  overflow-x: hidden;
}

body {
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  /* Doing for smooth scroll */
  /* height: 100vh;
  width: 100vw; */
}

::-webkit-scrollbar {
  display: none;
}

/*=================\   Fonts  \====================*/

@font-face {
  font-family: "Grotesk";
  font-weight: 300;
  src: url(../Assets/Fonts/OverusedGrotesk-Light.ttf);
}
@font-face {
  font-family: "Grotesk";
  font-weight: 400;
  src: url(../Assets/Fonts/OverusedGrotesk-Roman.ttf);
}
@font-face {
  font-family: "Grotesk";
  font-weight: 500;
  src: url(../Assets/Fonts/OverusedGrotesk-Medium.ttf);
}
@font-face {
  font-family: "Grotesk";
  font-weight: 600;
  src: url(../Assets/Fonts/OverusedGrotesk-SemiBold.ttf);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Grotesk", sans-serif;
  /* font-family: "Reem Kufi", sans-serif; */
}

/*=================\   Variables  \====================*/

:root {
  --loader-rounding-height: 450px;
  /* --loader-rounding-height: 250px; */
}

@media (max-width: 768px) {
  :root {
    --loader-rounding-height: 250px;
    /* --loader-rounding-height: 250px; */
  }
}

/*=================\   Theme  \====================*/

.light {
  background-color: white;
  transition: all ease-out 0.4s;
}

.light p,
.light a,
.light h1,
.light h2,
.light h3,
.light h4,
.light h5,
.light h6 {
  color: black;
}

.light a:hover {
  color: black;
}

.dark {
  background-color: #0f0f0f;
  color: white;
  transition: all ease-out 0.4s;
}

/* .dark .main-heading,
.dark .main-heading2,
.dark .main-heading3 {
  text-shadow: 2px 2px 12px #1b1b1b5b;
} */

.dark a {
  color: white;
}
.dark a:hover {
  color: white;
}

/*=================\   Loading  \====================*/

.web-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-overflow-hidden {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-text {
  /* font-size: 10rem; */
  font-size: calc(10px + (150 - 14) * ((100vw - 300px) / (1600 - 300)));
  line-height: 0.9;
  /* animation: 0.6s fade-in-up ease-out both; */
  animation: 0.7s rotated-slide-up 0s cubic-bezier(0, 0.01, 0.01, 1) both;
}

/* .kill-container {
  margin: 0 auto;
  width: 100vw;
  height: 100vh;

  border-bottom: 0;
  background: black;
  position: relative;
}

.kill-container:after {
  content: "";
  position: absolute;
  height: 150px;
  left: -10%;
  right: -10%;
  border-radius: 100%;

  bottom: 0px;

  background: black;
  z-index: 2;
} */
/* 
.kill-container:before {
  content: "";
  position: absolute;
  height: 100px;
  left: -10%;
  right: -10%;
  border-radius: 50%;
  bottom: -62px;
  background: #000;
  z-index: 1;
} */

.fade-out-loading {
  /* & div {
    animation: 0.35s rotated-slide-up-out 1.5s ease-out both;
  } */
  animation: 0.35s fade-out-animation 1.5s ease-out both;
}

.new-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100svh;
  width: 100vw;
  position: fixed;
  z-index: 4;

  & .new-loader-inner-container {
    width: 100vw;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
    /* transform: translateY(calc(100svh + var(--loader-rounding-height))); */

    animation: 0.6s loader-swiped-up-test 0.24s ease-in-out both;

    /* animation: 1.4s loader-swiped-up 0.2s cubic-bezier(0, 0.01, 0.01, 1) both; */

    &::before {
      content: "";
      position: absolute;
      width: 120vw;
      left: -10vw;
      border-radius: 100%;
      height: var(--loader-rounding-height);
      top: calc((var(--loader-rounding-height) / 2) * -1);
      background: black;
      z-index: 2;
      animation: 0.85s before-reducer 0.1s ease-in-out both;
    }

    &::after {
      content: "";
      position: absolute;
      width: 120vw;
      left: -10vw;
      border-radius: 100%;
      height: var(--loader-rounding-height);
      bottom: calc((var(--loader-rounding-height) / 2) * -1);
      background: black;
      z-index: 2;
      /* animation: 1s after-reducer 0.1s ease-in-out both; */
    }

    & > h1 {
      color: white;
      animation: 0.6s small-text-slide-up 0.6s ease-out both;
      font-size: 16px;
      letter-spacing: 4px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  &.new-reverse-animate {
    justify-content: flex-start;

    & .new-loader-inner-container {
      animation: 0.6s loader-swiped-up-out-test 0.2s ease-in-out both;

      &::after {
        animation: 0.85s after-reducer 0.1s ease-in-out both;
      }

      & > h1 {
        animation: 0.6s small-text-slide-up-out 0.1s ease-out both;
      }
    }
  }
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100svh;
  width: 100vw;

  & .loader-inner-container {
    width: 100vw;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 0.6s loader-swiped-up 0.1s ease-in-out both;
    /* animation: 1.4s loader-swiped-up 0.2s cubic-bezier(0, 0.01, 0.01, 1) both; */

    & > h1 {
      color: white;
      animation: 0.6s small-text-slide-up 0.4s ease-out both;
      font-size: 16px;
      letter-spacing: 4px;
      text-transform: uppercase;
    }
  }

  &.reverse-animate {
    justify-content: flex-start;

    & .loader-inner-container {
      animation: 0.6s loader-swiped-up-out 0.2s ease-in-out both;

      & > h1 {
        animation: 0.6s small-text-slide-up-out 0.1s ease-out both;
      }
    }
  }
}

@media (max-width: 768px) {
  .loading-text {
    font-size: 3.5rem;
  }
}

@keyframes loader-swiped-up {
  0% {
    transform: translateY(100svh);
    height: 100svh;
    border-top-left-radius: 200% 20%;
    border-top-right-radius: 200% 20%;
  }

  100% {
    transform: translateY(0svh);
    height: 100svh;
    border-top-left-radius: 0% 0%;
    border-top-right-radius: 0% 0%;
  }
}

@keyframes loader-swiped-up-test {
  0% {
    transform: translateY(calc(100vh));
    /* transform: translateY(calc(100svh + var(--loader-rounding-height))); */
    /* height: 100svh; */
    /* border-top-left-radius: 200% 20%; */
    /* border-top-right-radius: 200% 20%; */
  }

  100% {
    transform: translateY(0vh);
    /* height: 100svh; */
    /* border-top-left-radius: 0% 0%; */
    /* border-top-right-radius: 0% 0%; */
  }
}

@keyframes before-reducer {
  /* 0% {
    height: var(--loader-rounding-height);
    top: calc((var(--loader-rounding-height) / 2) * -1);
  } */

  0% {
    height: 0;
    top: 0;
  }

  40% {
    height: calc(var(--loader-rounding-height));
    top: calc(((var(--loader-rounding-height)) / 2) * -1);
    /* height: calc(var(--loader-rounding-height) + 50px);
    top: calc(((var(--loader-rounding-height) + 50px) / 2) * -1); */
  }

  100% {
    height: 0;
    top: 0;
  }
}

@keyframes after-reducer {
  /* 0% {
    height: var(--loader-rounding-height);
    top: calc((var(--loader-rounding-height) / 2) * -1);
  } */

  0% {
    height: 0;
    bottom: 0;
    /* height: calc(var(--loader-rounding-height));
    bottom: calc(((var(--loader-rounding-height)) / 2) * -1); */
  }

  40% {
    height: calc(var(--loader-rounding-height));
    bottom: calc(((var(--loader-rounding-height)) / 2) * -1);
    /* height: calc(var(--loader-rounding-height) + 50px);
    bottom: calc(((var(--loader-rounding-height) + 50px) / 2) * -1); */
  }

  100% {
    height: 0;
    bottom: 0;
  }
}

@keyframes loader-swiped-up-out-test {
  0% {
    transform: translateY(0vh);
    /* height: 100svh; */
    /* border-bottom-left-radius: 0% 0%; */
    /* border-bottom-right-radius: 0% 0%; */
  }

  100% {
    transform: translateY(-100vh);
    /* height: 100svh; */
    /* border-bottom-left-radius: 200% 20%; */
    /* border-bottom-right-radius: 200% 20%; */
  }
}

@keyframes loader-swiped-up-out {
  0% {
    transform: translateY(0svh);
    height: 100svh;
    border-bottom-left-radius: 0% 0%;
    border-bottom-right-radius: 0% 0%;
  }

  100% {
    transform: translateY(-100svh);
    height: 100svh;
    border-bottom-left-radius: 200% 20%;
    border-bottom-right-radius: 200% 20%;
  }
}

@keyframes small-text-slide-up {
  0% {
    opacity: 0;
    transform: translateY(400%);
  }
  20% {
    opacity: 1;
    /* transform: translateY(0%); */
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes small-text-slide-up-out {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  20% {
    opacity: 1;
    /* transform: translateY(0%); */
  }
  100% {
    opacity: 0;
    transform: translateY(-400%);
  }
}

@keyframes rotated-slide-up {
  from {
    transform: translateY(150%) rotate(20deg);
  }
  to {
    transform: translateY(0%) rotate(0deg);
  }
}

@keyframes rotated-slide-up-out {
  from {
    transform: translateY(0%) rotate(0deg);
  }
  to {
    transform: translateY(-150%) rotate(10deg);
  }
}

@keyframes fade-in-up {
  0% {
    transform: translateY(70%);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fade-out-animation {
  0% {
    opacity: 1;
    filter: blur(0);
  }
  100% {
    filter: blur(15px);
    opacity: 0;
  }
}

/*=================\   Header  \====================*/

.header {
  text-align: center;
  padding: 50px;
  max-width: 1280px;
  margin: auto;
  visibility: hidden;
  /* overflow: hidden; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  & p {
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 16px;
  }

  &.visible-header {
    visibility: visible;

    & div {
      & p {
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 16px;
        animation: 1s header-slide-up 0.9s ease-in-out both;
      }

      &:nth-of-type(1) {
        & p {
          animation-delay: 0.2s;
        }
      }
      &:nth-child(2) {
        & p {
          animation-delay: 0.185s;
        }
      }
      &:nth-child(3) {
        & p {
          animation-delay: 0.2s;
        }
      }
    }
  }
}

@keyframes header-slide-up {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media (min-width: 450px) and (max-width: 768px) {
  .header {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 450px) {
  .header {
    padding: 45px 10px;
    grid-template-columns: 1fr;

    & p {
      font-size: 16px;
    }
  }
}

/*=================\  Hero  \====================*/

.hero {
  max-width: 1280px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  visibility: hidden;

  & .main-head {
    text-align: center;
    padding: 45px 0px;
    position: absolute;
    z-index: 1;

    & div {
      /* overflow: hidden; */
    }

    & h1 {
      font-size: calc(50px + (200 - 14) * ((100vw - 300px) / (1600 - 300)));
      line-height: 0.86;
      font-weight: 600;
    }
  }

  & .main-img {
    /* width: 100%; */
    margin-inline: auto;
    width: 50%;
    height: 600px;
    margin-top: 276px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    margin-bottom: 150px;

    & .hero-img {
      position: relative;
    }
  }

  &.hero-visible {
    visibility: visible;
    animation: 0.7s hero-slide-up 0.3s ease-out both;

    & .main-head {
      & h1 {
        &.main-heading {
          animation: 0.65s text-slide-up 0.25s ease-out backwards;
        }
        &.main-heading2 {
          animation: 0.65s text-slide-up 0.285s ease-out backwards;
        }
        &.main-heading3 {
          animation: 0.65s text-slide-up 0.31s ease-out backwards;
        }
        /* &.main-heading {
          animation: 0.72s text-slide-up 0.31s ease-out backwards;
        }
        &.main-heading2 {
          animation: 0.72s text-slide-up 0.28s ease-out backwards;
        }
        &.main-heading3 {
          animation: 0.72s text-slide-up 0.25s ease-out backwards;
        } */
      }
    }

    & .main-img {
      animation: 0.65s slide-up-hero-img 0.3s ease-out both;
      /* animation: 0.72s slide-up-hero-img 0.32s ease-out both; */
    }
  }
}

@keyframes slide-in-down {
  0% {
    transform: translateY(-120%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes hero-slide-up {
  0% {
    transform: translateY(250px);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes text-slide-up {
  0% {
    transform: translateY(320%) rotate(0deg);
  }
  100% {
    transform: translateY(0%) rotate(0deg);
  }
}

@keyframes slide-up-hero-img {
  0% {
    transform: translateY(80%);
    /* opacity: 0; */
  }
  100% {
    transform: translateY(0%);
    /* opacity: 1; */
  }
}

@media (min-width: 450px) and (max-width: 768px) {
  .hero {
    & .main-head {
      padding: 35px 0px;
      & h1 {
        line-height: 1;
      }
    }

    & .main-img {
      width: calc(100% - 40px);
      height: 650px;
      margin-top: 180px;
      margin-bottom: 120px;
    }
  }
}

@media (max-width: 450px) {
  .hero {
    & .main-head {
      padding: 30px 0px;
      & h1 {
        line-height: 1.1;
      }
    }

    & .main-img {
      width: calc(100% - 40px);
      height: 480px;
      margin-top: 160px;
      margin-bottom: 120px;
    }
  }
}

/*======\  Intro  \=========*/

.intro {
  padding: 120px 230px;
  padding-top: 160px;
}

.intro .row {
  max-width: calc(1280px - 380px);
  margin: auto;
}

.intro div {
  transition: all ease-out 0.4s;
}

.hide-heading {
  /* opacity: 0; */
  transform: translateY(-140%);
  transition: all ease-out 0.4s;
}

.textbox {
  overflow-y: hidden;
}

.textbox h4,
.textbox p {
  transition: all ease-out 0.4s;
}

.intro-light {
  background-color: rgb(252, 252, 252);
  transition: all ease-out 0.4s;
}

.intro-dark {
  background-color: #1a1a1a;
  transition: all ease-out 0.4s;
}

.intro p {
  font-size: 18px;
}

.intro h4 {
  font-size: 20px;
  font-weight: bolder;
}

.intro i {
  font-size: 50px;
  margin-top: 20px;
  margin-right: 40px;
}

@media (min-width: 450px) and (max-width: 768px) {
  .intro {
    padding: 120px 80px;
    padding-top: 100px;
  }
}

@media (max-width: 450px) {
  .intro {
    padding: 70px 30px;
    padding-top: 100px;
  }
}

.hide {
  opacity: 0;
  transform: translateY(75px);
  transition: all ease-out 0.4s;
}

/*=======\ My projects \========*/

.projects {
  padding: 160px 230px 120px;
  transition: all ease-out 0.4s;
}

.projects-inner-div {
  max-width: calc(1280px - 380px);
  margin: auto;
}

.projects h1 {
  font-size: 65px;
  font-weight: bolder;
}

.myprojects {
  transition: all ease-out 0.4s;
}

.space {
  margin-bottom: 5px;
}

@media (min-width: 450px) and (max-width: 768px) {
  .projects {
    padding: 100px 170px 70px;
  }

  .projects h1 {
    font-size: 45px;
  }

  .space {
    margin-bottom: 190px;
  }
}

@media (max-width: 450px) {
  .projects {
    padding: 100px 30px 70px;
  }

  .projects h1 {
    font-size: 45px;
  }

  .space {
    margin-bottom: 90px;
  }
}

/*======\ Project \========*/

.project {
  display: flex;
  align-items: center;
  padding: 70px 0px;
  /* background-color: lightpink; */
  /* justify-content: center; */
}

/*======\ Project Description \========*/
.project-details {
  padding: 150px 0px;
}

@media (min-width: 450px) and (max-width: 768px) {
  .project-details {
    padding-top: 230px;
    /* padding-top: 10px; */
  }
}

@media (max-width: 450px) {
  .project-details {
    padding-top: 300px;
    /* padding-top: 10px; */
  }
}

.project-title {
  text-transform: uppercase;
  font-size: 22px;
}

.project-description {
  margin: 2px 0px;
}

.project-description span {
  color: gray;
}

.project-link {
  text-decoration: underline;
  cursor: pointer;
  transition: all ease-out 0.3s;
}

/*======\ Project Image Block \========*/

.project-img-block {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 200px 0px; */
}

.project-img-block .p-head {
  position: absolute;
  font-size: 400px;
  font-weight: 600;
  /* margin-top: -150px; */
}

@media (max-width: 768px) {
  .project-img-block .p-head {
    font-size: 250px;
  }
}

.project-img {
  margin-top: 270px;
  position: absolute;
  /* margin-bottom: -150px; */
  width: 100%;
  z-index: 1;
}

@media (max-width: 450px) {
  .project-img {
    margin-top: 350px;
  }
}
/*=====\ Footer \======*/

/* .footer-container {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  left: 0;
} */

.upper-footer {
  margin-top: 20px;
  padding: 40px 230px;
  padding-top: 50px;
}

.footer {
  max-width: calc(1280px - 380px);
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.upper-footer h3:nth-child(2) {
  text-align: right;
}

.link-button {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.link-button a:hover {
  text-decoration: none;
}

.line {
  height: 2px;
  width: 100%;
  margin-top: -10px;
  transition: all ease-out 0.7s;
}

.line-light {
  transition: all ease-out 0.4s;
  background-color: black;
}

.line-dark {
  transition: all ease-out 0.4s;
  background-color: white;
}

.link-button:hover .line {
  width: 0%;
}

@media (max-width: 768px) {
  .upper-footer {
    padding: 40px 30px;
  }
  .footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer h3:nth-child(2) {
    text-align: left;
  }

  .link-button {
    cursor: pointer;
    margin-top: 20px;
  }
}

.lower-footer {
  text-align: center;
  font-size: 14px;
  padding: 8px;
  padding-top: 20px;
}

/*==========\ Dark Mode \==========*/

.dark-box {
  z-index: 3;
  border-radius: 15px 0px 0px 15px;
  padding: 10px;
  padding-right: 25px;
  position: fixed;
  top: 15vh;
  right: 0;
  webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(233, 233, 233, 0.411);
  animation: 0.7s slide-in-left 0.3s ease-out both;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(0%);
  }
}

.dark-mode {
  z-index: 4;
  color: white;
  padding: 10px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #4a4a4a;
  outline: none;
}

.dark-mode:active,
.dark-mode:focus {
  outline: none;
}

.dark-mode i {
  font-size: 13px;
}

.dark-mode:hover {
  background-color: #6d6d6d;
}

@media (max-width: 768px) {
  .pc-only {
    display: none;
  }

  .dark-mode i {
    font-size: 20px;
  }

  .dark-box {
    padding: 6px;
    padding-right: 15px;
    top: 12vh;
  }
}

button {
  background-color: #3b80fe;
  border: 0px;
  border-radius: 100px;
  padding: 12px 60px;
  width: auto;
  color: #fafafa;
  transition: 0.3s all ease-out;
}

button:hover {
  background-color: #215ac4;
  box-shadow: 0 13px 12px 0 rgba(0, 0, 0, 0.15);
}
